import React from 'react'

import { Amplitude, LogOnMount } from '@amplitude/react-amplitude'

import './Thankyou/Thankyou.css'
import memes from './Thankyou/memes.json'
import labels from './Thankyou/labels.json'

function getRandomIntInclusive(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

const i = getRandomIntInclusive(0, memes.length - 1)
const filename = memes[i]
const path = '/img/meme'
const j = getRandomIntInclusive(0, labels.length - 1)
const label = labels[j]

function Thankyou() {
  return (
    <Amplitude
      eventProperties={{
        scope: ['page'],
        title: 'Muchas gracias',
        url: '/gracias',
        image: filename,
        message: label
      }}
    >
      <LogOnMount eventType="Page viewed" />
      <div className="App">
        <header className="App-header">
          <img src={`${path}/${filename}`} className="App-logo" alt="Thank you" />
          <p>Gracias por completar la solicitud.</p>
          <Amplitude
            eventProperties={inheritedProps => ({
              ...inheritedProps,
              scope: [...inheritedProps.scope, 'link']
            })}
          >
            {({ instrument }) => (
              <a
                className="App-link"
                href="/"
                target="_self"
                rel="noopener noreferrer"
                onClick={instrument('Clicked on a link')}
              >
                {label}
              </a>
            )}
          </Amplitude>
        </header>
      </div>
    </Amplitude>
  )
}

export default Thankyou
