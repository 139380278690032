import React, { useState, useEffect } from 'react'
import { Amplitude, LogOnChange } from '@amplitude/react-amplitude'

const optionsTipoVia = (
  <>
    <option value="MN">Al Municipi</option>
    <option value="AP">Apartat de Correus</option>
    <option value="AV">Avinguda</option>
    <option value="BO">BARRIO</option>
    <option value="BL">Bloc</option>
    <option value="CALLE">Calle</option>
    <option value="CL">Calle</option>
    <option value="CM">Camí </option>
    <option value="CR">Carrer</option>
    <option value="CO">Carreró</option>
    <option value="CT">Carretera</option>
    <option value="DS">Disseminat</option>
    <option value="GL">Glorieta </option>
    <option value="JR">Jardí</option>
    <option value="LU">LU</option>
    <option value="LUGAR">LUGAR</option>
    <option value="PASEO">PASEO</option>
    <option value="PC">Parc</option>
    <option value="PQ">Parc</option>
    <option value="PD">Partida</option>
    <option value="PG">Passatge</option>
    <option value="PS">Passeig</option>
    <option value="PT">Pati</option>
    <option value="PL">Plaça</option>
    <option value="RB">Rambla</option>
    <option value="RS">Raseta</option>
    <option value="RV">Raval </option>
    <option value="RI">Riera</option>
    <option value="RD">Ronda</option>
    <option value="TV">Travessera</option>
    <option value="UR">Urbanització</option>
    <option value="VP">Verd Públic</option>
    <option value="AR">Àrea</option>
    <option value="MS">MS</option>
    <option value="PN">PN</option>
    <option value="ES">ES</option>
    <option value="ER">ER</option>
    <option value="CS">CS</option>
    <option value="TR">TR</option>
    <option value="TM">TM</option>
    <option value="LG">LG</option>
    <option value="ED">ED</option>
    <option value="GV">GV</option>
  </>
)

function Addressfield(props) {
  const { id, required, type, label } = props

  const [address, setAddress] = useState('')
  const [typingA, setTypingA] = useState(false)

  const [country, setCountry] = useState('')
  const [typingCO, setTypingCO] = useState(false)

  const [province, setProvince] = useState('')
  const [typingPR, setTypingPR] = useState(false)

  const [city, setCity] = useState('')
  const [typingCI, setTypingCI] = useState(false)

  const [postal, setPostal] = useState('')
  const [typingPC, setTypingPC] = useState(false)

  const [typeOfStreet, setTypeOfStreet] = useState('')

  useEffect(() => {
    const timeout = setTimeout(function() {
      setTypingA(false)
      console.log(`Stopped typing text: ${address}`)
    }, 1000)
    return () => clearTimeout(timeout)
  }, [address])

  useEffect(() => {
    const timeout = setTimeout(function() {
      setTypingCO(false)
      console.log(`Stopped typing text: ${country}`)
    }, 1000)
    return () => clearTimeout(timeout)
  }, [country])

  useEffect(() => {
    const timeout = setTimeout(function() {
      setTypingPR(false)
      console.log(`Stopped typing text: ${province}`)
    }, 1000)
    return () => clearTimeout(timeout)
  }, [province])

  useEffect(() => {
    const timeout = setTimeout(function() {
      setTypingCI(false)
      console.log(`Stopped typing text: ${city}`)
    }, 1000)
    return () => clearTimeout(timeout)
  }, [city])

  useEffect(() => {
    const timeout = setTimeout(function() {
      setTypingPC(false)
      console.log(`Stopped typing text: ${postal}`)
    }, 1000)
    return () => clearTimeout(timeout)
  }, [postal])

  function handleTypingAddress(e) {
    setTypingA(true)
    setAddress(e.target.value)
  }

  function handleTypingCountry(e) {
    setTypingCO(true)
    setCountry(e.target.value)
  }

  function handleTypingProvince(e) {
    setTypingPR(true)
    setProvince(e.target.value)
  }

  function handleTypingCity(e) {
    setTypingCI(true)
    setCity(e.target.value)
  }

  function handleTypingPostal(e) {
    setTypingPC(true)
    setPostal(e.target.value)
  }

  function handleSelection(e) {
    setTypeOfStreet(e.target.value)
    console.log(`Selected value: ${e.target.value}`)
  }

  return (
    <Amplitude
      eventProperties={inheritedProps => ({
        ...inheritedProps,
        scope: [...inheritedProps.scope, 'field'],
        fieldType: type,
        fieldLabel: label
      })}
    >
      {({ instrument }) => (
        <>
          <LogOnChange
            value={typingCO}
            eventType={typingCO ? 'Started typing on field' : 'Stopped typing on field'}
            eventProperties={inheritedProps => ({
              ...inheritedProps,
              scope: [...inheritedProps.scope, 'subfield'],
              subfieldType: 'text',
              subfieldLabel: 'País'
            })}
          />
          <LogOnChange
            value={typingPR}
            eventType={typingPR ? 'Started typing on field' : 'Stopped typing on field'}
            eventProperties={inheritedProps => ({
              ...inheritedProps,
              scope: [...inheritedProps.scope, 'subfield'],
              subfieldType: 'text',
              subfieldLabel: 'Provincia'
            })}
          />
          <LogOnChange
            value={typingCI}
            eventType={typingCI ? 'Started typing on field' : 'Stopped typing on field'}
            eventProperties={inheritedProps => ({
              ...inheritedProps,
              scope: [...inheritedProps.scope, 'subfield'],
              subfieldType: 'text',
              subfieldLabel: 'Municipio'
            })}
          />
          <LogOnChange
            value={typingPC}
            eventType={typingPC ? 'Started typing on field' : 'Stopped typing on field'}
            eventProperties={inheritedProps => ({
              ...inheritedProps,
              scope: [...inheritedProps.scope, 'subfield'],
              subfieldType: 'text',
              subfieldLabel: 'Código postal'
            })}
          />
          <LogOnChange
            value={typingA}
            eventType={typingA ? 'Started typing on field' : 'Stopped typing on field'}
            eventProperties={inheritedProps => ({
              ...inheritedProps,
              scope: [...inheritedProps.scope, 'subfield'],
              subfieldType: 'text',
              subfieldLabel: 'Calle'
            })}
          />
          <LogOnChange
            value={typeOfStreet}
            eventType="Made a selection on a field"
            eventProperties={inheritedProps => ({
              ...inheritedProps,
              scope: [...inheritedProps.scope, 'subfield'],
              subfieldType: 'select',
              subfieldLabel: 'Tipo de vía'
            })}
          />
          {/* <LogOnChange value={typed} eventType="Finished typing on field" /> */}
          <div id={id}>
            <p style={{ minHeight: '3em' }}>
              <span class="campoHoriz">
                <input
                  type="text"
                  tabindex="561"
                  size="15"
                  id="pais"
                  name="pais"
                  maxlength="50"
                  required={required}
                  className={required && 'inputObligatorio'}
                  // onFocus={instrument('Field focused')}
                  onChange={e => handleTypingCountry(e)}
                />
                <label for="pais">País</label>
              </span>

              <span class="campoHoriz">
                <input
                  type="text"
                  tabindex="567"
                  id="provincia"
                  name="provincia"
                  size="15"
                  maxlength="50"
                  required={required}
                  className={required && 'inputObligatorio'}
                  // onFocus={instrument('Field focused')}
                  onChange={e => handleTypingProvince(e)}
                />

                <label for="provincia">Provincia</label>
              </span>
              <span class="campoHoriz">
                <input
                  type="text"
                  id="municipio"
                  tabindex="573"
                  name="municipio"
                  size="15"
                  maxlength="50"
                  required={required}
                  className={required && 'inputObligatorio'}
                  // onFocus={instrument('Field focused')}
                  onChange={e => handleTypingCity(e)}
                />

                <label for="municipio">Municipio</label>
              </span>
              <span class="campoHoriz">
                <input
                  type="text"
                  id="codigopostal"
                  tabindex="579"
                  maxlength="5"
                  name="codigopostal"
                  size="5"
                  required={required}
                  className={required && 'inputObligatorio'}
                  // onFocus={instrument('Field focused')}
                  onChange={e => handleTypingPostal(e)}
                />

                <label for="codigopostal">Código postal</label>
              </span>
            </p>
            <p>
              <span class="campoHoriz">
                <select
                  tabindex="585"
                  name="tipoVia"
                  id="tipoVia"
                  required={required}
                  className={required && 'inputObligatorio'}
                  onChange={e => handleSelection(e)}
                >
                  {optionsTipoVia}
                </select>

                <label for="tipoVia">Tipo de via</label>
              </span>
              <span class="campoHoriz">
                <input
                  tabindex="631"
                  type="text"
                  title="Escriba el nombre de su calle"
                  id={id}
                  name={id}
                  size="20"
                  maxlength="50"
                  required={required}
                  className={required && 'inputObligatorio'}
                  // onFocus={instrument('Field focused')}
                  onChange={e => handleTypingAddress(e)}
                />

                <label title="Escriba el nombre de su calle" for={id}>
                  Calle
                </label>
              </span>

              <span class="campoHoriz">
                {required ? (
                  <input
                    type="text"
                    style={{ width: '2.5em', marginRight: '0em', marginLeft: '0em' }}
                    tabindex="638"
                    class="inputObligatorio"
                    required={required}
                    title="En caso de que su número de calle sea compuesto, utilice el segundo campo para tal efecto. No use el segundo campo para poner el piso o letra"
                    name="kmcalleText"
                    id="kmcalleText"
                    maxlength="5"
                    size="2"
                  />
                ) : (
                  <input
                    type="text"
                    style={{ width: '2.5em', marginRight: '0em', marginLeft: '0em' }}
                    tabindex="638"
                    title="En caso de que su número de calle sea compuesto, utilice el segundo campo para tal efecto. No use el segundo campo para poner el piso o letra"
                    name="kmcalleText"
                    id="kmcalleText"
                    maxlength="5"
                    size="2"
                  />
                )}

                <input
                  type="text"
                  style={{ width: '2.5em', marginRight: '0em', marginLeft: '0em' }}
                  tabindex="639"
                  title="En caso de que su número de calle sea compuesto, utilice el segundo campo para tal efecto. No use el segundo campo para poner el piso o letra"
                  name="kmcalle2"
                  id="kmcalle2"
                  maxlength="5"
                  size="2"
                />
                <table cellpadding="0" cellspacing="0">
                  <tbody>
                    <tr>
                      <td>
                        <div id="btnIndKm" style={{ display: 'block' }}>
                          <select
                            className="addressSelector"
                            name="btnIndKm"
                            id="btnIndKm-select"
                            onChange={event =>
                              (document.getElementById('indKmDiv').innerHTML =
                                event.target.options[event.target.selectedIndex].text)
                            }
                          >
                            <option value="num">Número</option>
                            <option value="km">Kilom</option>
                            <option value="manzana">Manz</option>
                            <option value="parcela">Parc.Rúst.</option>
                          </select>
                        </div>
                      </td>
                      <td>
                        <label
                          title="En caso de que su número de calle sea compuesto, utilice el segundo campo para tal efecto. No use el segundo campo para poner el piso o letra"
                          for="kmcalleText"
                        >
                          <div class="etiquetaDiv" id="indKmDiv">
                            Número
                          </div>
                        </label>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </span>
              <span class="campoHoriz">
                <input
                  type="text"
                  style={{ width: '2.5em' }}
                  tabindex="650"
                  title="Bis o duplicado del número de acceso"
                  name="dupli"
                  id="dupli"
                  maxlength="2"
                  size="2"
                />

                <label title="Bis o duplicado del número de acceso" for="dupli">
                  Bis
                </label>
              </span>
              <span class="campoHoriz">
                <input
                  type="text"
                  style={{ width: '3em', marginRight: '0em' }}
                  tabindex="653"
                  title="Seleccione una de las opciones disponibles para el Bloque/Poligono/Barraca/Portal/Bl.Por./Lote"
                  name="bloque"
                  id="bloque"
                  size="2"
                  maxlength="4"
                />
                <table cellpadding="0" cellspacing="0">
                  <tbody>
                    <tr>
                      <td>
                        <div id="btnIndBlock" style={{ display: 'block' }}>
                          <select
                            className="addressSelector"
                            name="btnIndBlock"
                            id="btnIndBlock-select"
                            onChange={event =>
                              (document.getElementById('indBlockDiv').innerHTML =
                                event.target.options[event.target.selectedIndex].text)
                            }
                          >
                            <option value=""></option>
                            <option value="bloque">Bloque</option>
                            <option value="polig">Políg</option>
                            <option value="barraca">Barraca</option>
                            <option value="portal">Portal</option>
                            <option value="bipor">BI./Por</option>
                            <option value="lote">Lote</option>
                          </select>
                        </div>
                      </td>
                      <td>
                        <label
                          title="Seleccione una de las opciones disponibles para el Bloque/Poligono/Barraca/Portal/Bl.Por./Lote"
                          for="bloque"
                        >
                          <div class="etiquetaDiv" id="indBlockDiv"></div>
                        </label>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </span>
              <span class="campoHoriz">
                <input
                  type="text"
                  style={{ width: '30px', marginRight: '0em' }}
                  tabindex="664"
                  title="Seleccione una de las opciones disponibles"
                  name="escalera"
                  id="escalera"
                  size="2"
                  maxlength="2"
                />
                <table cellpadding="0" cellspacing="0">
                  <tbody>
                    <tr>
                      <td>
                        <div id="btnIndStair" style={{ display: 'block' }}>
                          <select
                            className="addressSelector"
                            name="btnIndStair"
                            id="btnIndStair-select"
                            onChange={event =>
                              (document.getElementById('escalera').value = event.target.value)
                            }
                          >
                            <option value=""></option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                            <option value="D">D</option>
                            <option value="DR">DR</option>
                            <option value="IZ">IZ</option>
                          </select>
                        </div>
                      </td>
                      <td>
                        <label title="Seleccione una de las opciones disponibles" for="escalera">
                          <div class="etiquetaDiv" id="indStairDiv">
                            Esc.
                          </div>
                        </label>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </span>
              <span class="campoHoriz">
                <input
                  type="text"
                  style={{ width: '30px', marginRight: '0em' }}
                  tabindex="675"
                  name="piso"
                  id="piso"
                  title="Seleccione una de las opciones disponibles"
                  size="2"
                  maxlength="2"
                />
                <table cellpadding="0" cellspacing="0">
                  <tbody>
                    <tr>
                      <td>
                        <div id="btnIndFloor" style={{ display: 'block' }}>
                          <select
                            className="addressSelector"
                            name="btnIndFloor"
                            id="btnIndFloor-select"
                            onChange={event =>
                              (document.getElementById('piso').value = event.target.value)
                            }
                          >
                            <option value=""></option>
                            <option value="-1">-1</option>
                            <option value="-2">-2</option>
                            <option value="0">00</option>
                            <option value="1">01</option>
                            <option value="2">02</option>
                            <option value="3">03</option>
                            <option value="4">04</option>
                            <option value="5">05</option>
                            <option value="6">06</option>
                            <option value="AT">AT</option>
                            <option value="SA">SA</option>
                            <option value="BJ">BJ</option>
                            <option value="PR">PR</option>
                          </select>
                        </div>
                      </td>
                      <td>
                        <label title="Seleccione una de las opciones disponibles" for="piso">
                          <div class="etiquetaDiv" id="indFloorDiv">
                            Piso
                          </div>
                        </label>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </span>
              <span class="campoHoriz">
                <input
                  type="text"
                  style={{ width: '3em', marginRight: '0em' }}
                  tabindex="686"
                  name="puerta"
                  id="puerta"
                  title="Seleccione una de las opciones disponibles"
                  size="2"
                  maxlength="4"
                />
                <table cellpadding="0" cellspacing="0">
                  <tbody>
                    <tr>
                      <td>
                        <div id="btnIndDoor" style={{ display: 'block' }}>
                          <select
                            className="addressSelector"
                            name="btnIndDoor"
                            id="btnIndDoor-select"
                            onChange={event =>
                              (document.getElementById('puerta').value = event.target.value)
                            }
                          >
                            <option value=""></option>
                            <option value="001">001</option>
                            <option value="002">002</option>
                            <option value="003">003</option>
                            <option value="004">004</option>
                            <option value="005">005</option>
                            <option value="006">006</option>
                            <option value="DER">DER</option>
                            <option value="IZQ">IZQ</option>
                          </select>
                        </div>
                      </td>
                      <td>
                        <label title="Seleccione una de las opciones disponibles" for="puerta">
                          <div class="etiquetaDiv" id="indDoorDiv">
                            Puerta
                          </div>
                        </label>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </span>
            </p>
          </div>
        </>
      )}
    </Amplitude>
  )
}

export default Addressfield
