import React from 'react'

// import './Home/tramitesresp.css'
import './Form/tramites.css'
import './Form/estilofijo.css'
import './Form/grupos.css'

import './Form/responsive.css'

import './Form/experiment.css'

import examplefields from './Form/examplefields.json'

import {
  Textfield,
  Textarea,
  Selectfield,
  Radiofield,
  Checkboxfield,
  Datefield,
  Numberfield,
  Addressfield
} from '../Fields'

function Field(props) {
  const { type } = props

  switch (type) {
    case 'text':
      return <Textfield {...props} />
    case 'area':
      return <Textarea {...props} />
    case 'select':
      return <Selectfield {...props} />
    case 'radio':
      return <Radiofield {...props} />
    case 'check':
      return <Checkboxfield {...props} />
    case 'date':
      return <Datefield {...props} />
    case 'number':
      return <Numberfield {...props} />
    case 'address':
      return <Addressfield {...props} />
    default:
      return null
  }
}

function Form() {
  const now = new Date()

  function handleSubmit(event) {
    event.preventDefault()
    const data = new FormData(event.target)
    console.log(Object.fromEntries(data))
  }

  return (
    <div id="contenedor">
      <div id="contenido">
        <div id="cuerpo">
          <div id="cabecera">
            <div className="titulo">Registro electrónico</div>
            <div className="clock">
              Hora:<span id="clock">{now.toLocaleTimeString('es-ES')}</span>
            </div>
          </div>
          <div id="lateral_izq" style={{ height: '85vh' }} />
          <div id="principal">
            <form
              action="/send"
              method="post"
              id="formulario"
              onSubmit={handleSubmit}
              name="formulario"
            >
              <div id="centro">
                <h1>Solicitud de prueba</h1>

                <ul className="pasos">
                  <li id="primero" className="primero">
                    <a tabindex="1" href="#">
                      1. Rellenar formulario
                    </a>
                  </li>
                  <li className="desactivado">
                    <a tabindex="2" href="#">
                      2. Firmar
                    </a>
                  </li>
                  <li className="ultimo desactivado">
                    <a tabindex="3" href="#">
                      3. Descargar justificante
                    </a>
                  </li>
                </ul>
                <div className="clear"></div>

                <div className="info_obligatorios">
                  <span className="obligatorio">&nbsp;&nbsp;</span> = Campos obligatorios
                </div>
                <p className="clear"></p>
                <div className="subtextoCabecera">
                  Solicitud de prueba para demostrar todos los tipos de campos
                </div>
                {/* Variables */}
                <div id="Variables">
                  <table width="100%" class="sectionVariable">
                    <tbody>
                      <tr class="headerSection">
                        <td>
                          <h2>Campos de ejemplo</h2>
                        </td>
                      </tr>
                      {examplefields.map(f => (
                        <tr>
                          <td>
                            <table width="100%">
                              <tbody>
                                <tr>
                                  <td width="150px" valign="top">
                                    {f.required ? (
                                      <label className="obligatorio">{`${f.label}:`}</label>
                                    ) : (
                                      <label>{`${f.label}:`}</label>
                                    )}
                                  </td>
                                  <td>
                                    <Field {...f} />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {/* end of Variables */}

                <div className="separacion"></div>
                {/* Botón submit */}
                <input className="boton-style tamano-defecto" type="submit" value="Enviar" />

                {/* Footer */}
                <br />
                <br />
                <br />
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <td style={{ width: '20.58%', height: '13px' }}>
                        <strong>Responsable</strong>
                      </td>
                      <td style={{ width: '77.16%', height: '13px' }}>Ayuntamiento de Tsystems</td>
                    </tr>
                    <tr>
                      <td style={{ width: '20.58%', height: '13px' }}>
                        <strong>Finalidad</strong>
                      </td>
                      <td style={{ width: '77.16%', height: '13px' }}>
                        Presentación de solicitudes en registro electrónico
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: '20.58%', height: '13px' }}>
                        <strong>Legitimación</strong>
                      </td>
                      <td style={{ width: '77.16%', height: '13px' }}>
                        Normativa de bases locales
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: '20.58%', height: '13px' }}>
                        <strong>Derechos</strong>
                      </td>
                      <td style={{ width: '77.16%', height: '13px' }}>
                        Acceder, rectificar y suprimir los datos, asi como otros derechos, como se
                        explica en la información adicional.
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: '20.58%', height: '13px' }}>
                        <strong>Información adicional</strong>
                      </td>
                      <td style={{ width: '77.16%', height: '13px' }}>
                        <a href="http://www.tao.es/">Mas información aqui</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div id="lateral_der" style={{ height: '85vh' }} />
            </form>
          </div>
        </div>
        <div id="pie">&nbsp;</div>
      </div>
    </div>
  )
}

export default Form
