import React, { useState, useEffect } from 'react'
import { Amplitude, LogOnChange } from '@amplitude/react-amplitude'

function Radiofield(props) {
  const { id, required, options, type, label } = props
  const [value, setValue] = useState('')
  const [typing, setTyping] = useState(false)
  const [typed, setTyped] = useState('')

  function handleSelection(e) {
    setValue(e.target.value)
    console.log(`Selected value: ${e.target.value}`)
  }

  return (
    <Amplitude
      eventProperties={inheritedProps => ({
        ...inheritedProps,
        scope: [...inheritedProps.scope, 'field'],
        fieldType: type,
        fieldLabel: label
      })}
    >
      {({ instrument }) => (
        <>
          <LogOnChange value={value} eventType="Made a selection on a field" />
          {options.map(el => (
            <>
              <input
                type="radio"
                id={`${id}-${el.value}`}
                name={id}
                value={el.value}
                required={required}
                className={required && 'inputObligatorio'}
                onChange={e => handleSelection(e)}
              />
              <label className="radioLabel" htmlFor={`${id}-${el.value}`}>
                {el.label}
              </label>
            </>
          ))}
        </>
      )}
    </Amplitude>
  )
}

export default Radiofield
