import React from 'react'

import Chance from 'chance'
import stringSimilarity from 'string-similarity'

import { Amplitude, LogOnMount } from '@amplitude/react-amplitude'
import { useHistory } from 'react-router-dom'

import { withFirebase } from '../Firebase'

// import './Home/tramitesresp.css'
import './Form/tramites.css'
import './Form/estilofijo.css'
import './Form/grupos.css'

import './Form/responsive.css'

import './Form/experiment.css'

import {
  Textfield,
  Textarea,
  Selectfield,
  Radiofield,
  Checkboxfield,
  Datefield,
  Numberfield,
  Addressfield
} from '../Fields'

import personal from './Home/personal.json'
import generic from './Home/generic.json'
import documents from './Home/documents.json'

function Field(props) {
  const { type } = props

  switch (type) {
    case 'text':
      return <Textfield {...props} />
    case 'area':
      return <Textarea {...props} />
    case 'select':
      return <Selectfield {...props} />
    case 'radio':
      return <Radiofield {...props} />
    case 'check':
      return <Checkboxfield {...props} />
    case 'date':
      return <Datefield {...props} />
    case 'number':
      return <Numberfield {...props} />
    case 'address':
      return <Addressfield {...props} />
    default:
      return null
  }
}

function getRandomIntInclusive(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

// Fisher-Yates array shuffle
function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }

  return array
}

// generar clave de la sesión
const chance = new Chance()
const session = `${chance.first({ nationality: 'en' })}-${chance.month()}-${chance.animal()}`
  .split(' ')
  .join('_')

// determinar núm de campos
const numfields = getRandomIntInclusive(3, 24)

// determinar complejidad
const numdocs = numfields > 10 ? getRandomIntInclusive(1, 3) : getRandomIntInclusive(0, 2)
const bureaucracy = numfields + 8 * numdocs

// distribuir campos entre las opciones [personales, obs, doc1, doc2, ...]
const distribution = Array(numdocs + 2) // personal + docs + obs
distribution.fill(1)
distribution[0] = 2 // min personales
distribution[1] = getRandomIntInclusive(0, 2) // min obs
let undistributed = numfields - distribution.reduce((a, b) => a + b, 0)
while (undistributed > 0) {
  distribution.forEach((el, i) => {
    if (undistributed > 0) {
      const r = getRandomIntInclusive(0, Math.min(undistributed, 3))
      if (i > 1) {
        const unfilled = 5 - el
        if (r <= unfilled) {
          distribution[i] += r
          undistributed -= r
        }
      } else if (i == 1) {
      } else {
        distribution[i] += r
        undistributed -= r
      }
    }
  })
}

// seleccionar personales
let personalFields = shuffle(personal)
personalFields = personalFields.slice(0, distribution[0])

// seleccionar documentos
let selectedDocuments = shuffle(documents)
selectedDocuments = selectedDocuments.slice(0, numdocs)
selectedDocuments.forEach((doc, i) => {
  let f = shuffle(doc.fields)
  doc.fields = f.slice(0, distribution[i + 2])
})

// seleccionar abiertos
let openFields = shuffle(generic)
openFields = openFields.slice(0, distribution[1])

console.log(
  `Campos: ${numfields} / Documentos: ${numdocs} / Burocracia: ${bureaucracy} / Clave: ${session}`
)
console.log(`Distribuidos: ${JSON.stringify(distribution, 2)}`)

// Firestore

function Home(props) {
  const now = new Date()
  const history = useHistory()
  const { firebase } = props

  function scoreMe(data) {
    // data es un objeto con pares key/value correspondientes a los id y valores de los campos

    let s = 0.0
    let i = 0

    // recorrer los documentos
    selectedDocuments.forEach(doc => {
      doc.fields.forEach(f => {
        if (f.type !== 'check' && f.required && f.hasOwnProperty('answer')) {
          // excluimos checks, no requeridos, y sin respuesta
          let val = 0
          if (f.type === 'number') {
            val =
              1 -
              Math.abs(
                (parseInt(f.answer, 10) - parseInt(data[f.id], 10)) /
                  (parseInt(f.answer, 10) + parseInt(data[f.id], 10)) /
                  2
              )
          } else if (f.type === 'radio' || f.type === 'select') {
            val = data[f.id] === f.answer ? 1 : 0
          } else {
            val = stringSimilarity.compareTwoStrings(data[f.id], f.answer)
          }
          // console.log(`Field ${f.label} scored ${val}`)
          s += val
          i += 1
        }
      })
    })

    console.log(`Score ${s} from ${i} fields`)
    // normalizamos
    if (i == 0) {
      s = 1
    } else {
      s = s / i
    }

    return s
  }

  function saveData(data) {
    const finish = new Date()
    const sc = scoreMe(data)

    const requestData = {
      requestStartTime: now,
      requestEndTime: finish,
      requestDuration: Math.ceil((finish - now) / 1000),
      requestSession: session,
      requestNumfields: numfields,
      requestNumdocs: numdocs,
      requestBureaucracy: bureaucracy,
      requestScore: sc
    }

    firebase
      .request(session)
      .set({
        ...requestData,
        ...data
      })
      .then(function() {
        console.log('Document successfully written!')
      })
      .catch(function(error) {
        console.error('Error writing document: ', error)
      })

    firebase
      .all()
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          console.log(doc.id, '=>', doc.data())
        })
      })
      .catch(function(error) {
        console.log('Error getting documents:', error)
      })
  }

  function handleSubmit(event, fn) {
    event.preventDefault()
    fn()
    console.log('Submit event logged')
    const data = new FormData(event.target)
    console.log(Object.fromEntries(data))
    saveData(Object.fromEntries(data))
    history.push('/gracias')
  }

  return (
    <Amplitude
      eventProperties={{
        scope: ['page'],
        title: 'Solicitud',
        url: '/'
      }}
    >
      <div id="contenedor">
        <div id="contenido">
          <div id="cuerpo">
            <div id="cabecera">
              <div className="titulo">Registro electrónico</div>
              <div className="clock">
                Hora:<span id="clock">{now.toLocaleTimeString('es-ES')}</span>
              </div>
            </div>
            <div id="lateral_izq" style={{ height: '85vh' }} />
            <div id="principal">
              <Amplitude
                eventProperties={inheritedProps => ({
                  ...inheritedProps,
                  scope: [...inheritedProps.scope, 'form'],
                  formKey: session,
                  formFields: numfields,
                  formDocs: numdocs,
                  formBureaucracy: bureaucracy
                })}
              >
                {({ logEvent }) => (
                  <form
                    action="/send"
                    method="post"
                    id="formulario"
                    onSubmit={e => {
                      const fn = () => logEvent('Form submitted')
                      handleSubmit(e, fn)
                    }}
                    name="formulario"
                  >
                    <LogOnMount eventType="Form viewed" />
                    <div id="centro">
                      <h1>Solicitud</h1>

                      <ul className="pasos">
                        <li id="primero" className="primero">
                          <a tabindex="1" href="#">
                            1. Rellenar formulario
                          </a>
                        </li>
                        <li className="desactivado">
                          <a tabindex="2" href="#">
                            2. Firmar
                          </a>
                        </li>
                        <li className="ultimo desactivado">
                          <a tabindex="3" href="#">
                            3. Descargar justificante
                          </a>
                        </li>
                      </ul>
                      <div className="clear"></div>

                      <div className="info_obligatorios">
                        <span className="obligatorio">&nbsp;&nbsp;</span> = Campos obligatorios
                      </div>
                      <p className="clear"></p>
                      <div className="subtextoCabecera">
                        Los datos introducidos no son el interés de este ejercicio y serán
                        destruidos después de ser enviados. <br />
                        De todas formas, si tienes algún reparo en contestar a alguno de los datos
                        personales <b>puedes inventártelo</b>.
                      </div>

                      {/* Variables */}
                      <div id="Variables">
                        {/* start of personal fields */}
                        <Amplitude
                          eventProperties={inheritedProps => ({
                            ...inheritedProps,
                            scope: [...inheritedProps.scope, 'personal']
                          })}
                        >
                          <table width="100%" class="sectionVariable">
                            <tbody>
                              <tr class="headerSection">
                                <td>
                                  <h2>Datos personales</h2>
                                </td>
                              </tr>
                              {personalFields.map(f => (
                                <tr>
                                  <td>
                                    <table width="100%">
                                      <tbody>
                                        <tr>
                                          <td width="150px" valign="top">
                                            {f.required ? (
                                              <label className="obligatorio">{`${f.label}:`}</label>
                                            ) : (
                                              <label>{`${f.label}:`}</label>
                                            )}
                                          </td>
                                          <td>
                                            <Field {...f} />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </Amplitude>

                        {/* start of document fields */}
                        {selectedDocuments.map(d => (
                          <Amplitude
                            eventProperties={inheritedProps => ({
                              ...inheritedProps,
                              scope: [...inheritedProps.scope, 'document'],
                              document: d.title
                            })}
                          >
                            <table width="100%" class="sectionVariable">
                              <tbody>
                                <tr class="headerSection">
                                  <td>
                                    <h2>{d.label}</h2>
                                  </td>
                                </tr>
                                {d.fields.map(f => (
                                  <tr>
                                    <td>
                                      <table width="100%">
                                        <tbody>
                                          <tr>
                                            <td width="150px" valign="top">
                                              {f.required ? (
                                                <label className="obligatorio">{`${f.label}:`}</label>
                                              ) : (
                                                <label>{`${f.label}:`}</label>
                                              )}
                                            </td>
                                            <td>
                                              <Field {...f} />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </Amplitude>
                        ))}

                        {/* start of open-ended fields */}
                        {distribution[1] > 0 && (
                          <Amplitude
                            eventProperties={inheritedProps => ({
                              ...inheritedProps,
                              scope: [...inheritedProps.scope, 'open-ended']
                            })}
                          >
                            <table width="100%" class="sectionVariable">
                              <tbody>
                                <tr class="headerSection">
                                  <td>
                                    <h2>Otros datos</h2>
                                  </td>
                                </tr>
                                {openFields.map(f => (
                                  <tr>
                                    <td>
                                      <table width="100%">
                                        <tbody>
                                          <tr>
                                            <td width="150px" valign="top">
                                              {f.required ? (
                                                <label className="obligatorio">{`${f.label}:`}</label>
                                              ) : (
                                                <label>{`${f.label}:`}</label>
                                              )}
                                            </td>
                                            <td>
                                              <Field {...f} />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </Amplitude>
                        )}
                      </div>
                      {/* end of Variables */}

                      <div className="separacion"></div>
                      {/* Botón submit */}
                      <input className="boton-style tamano-defecto" type="submit" value="Enviar" />

                      {/* Footer */}
                      <br />
                      <br />
                      <br />
                      <table style={{ width: '100%' }}>
                        <tbody>
                          <tr>
                            <td style={{ width: '20.58%', height: '13px' }}>
                              <strong>Responsable</strong>
                            </td>
                            <td style={{ width: '77.16%', height: '13px' }}>
                              Ayuntamiento de Tsystems
                            </td>
                          </tr>
                          <tr>
                            <td style={{ width: '20.58%', height: '13px' }}>
                              <strong>Finalidad</strong>
                            </td>
                            <td style={{ width: '77.16%', height: '13px' }}>
                              Presentación de solicitudes en registro electrónico
                            </td>
                          </tr>
                          <tr>
                            <td style={{ width: '20.58%', height: '13px' }}>
                              <strong>Legitimación</strong>
                            </td>
                            <td style={{ width: '77.16%', height: '13px' }}>
                              Normativa de bases locales
                            </td>
                          </tr>
                          <tr>
                            <td style={{ width: '20.58%', height: '13px' }}>
                              <strong>Derechos</strong>
                            </td>
                            <td style={{ width: '77.16%', height: '13px' }}>
                              Acceder, rectificar y suprimir los datos, asi como otros derechos,
                              como se explica en la información adicional.
                            </td>
                          </tr>
                          <tr>
                            <td style={{ width: '20.58%', height: '13px' }}>
                              <strong>Información adicional</strong>
                            </td>
                            <td style={{ width: '77.16%', height: '13px' }}>
                              <a href="http://www.tao.es/">Mas información aqui</a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div id="lateral_der" style={{ height: '85vh' }} />
                  </form>
                )}
              </Amplitude>
            </div>
          </div>
          <div id="pie">&nbsp;</div>
        </div>
      </div>
    </Amplitude>
  )
}

export default withFirebase(Home)
