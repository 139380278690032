import React, { useState, useEffect } from 'react'
import { Amplitude, LogOnChange } from '@amplitude/react-amplitude'

function optionElements(options) {
  return options.map(el => <option value={el.value}>{el.label}</option>)
}

function Selectfield(props) {
  const { id, required, options, type, label } = props
  const [value, setValue] = useState('')
  const [typing, setTyping] = useState(false)
  const [typed, setTyped] = useState('')

  function handleSelection(e) {
    setValue(e.target.value)
    console.log(`Selected value: ${e.target.value}`)
  }

  return (
    <Amplitude
      eventProperties={inheritedProps => ({
        ...inheritedProps,
        scope: [...inheritedProps.scope, 'field'],
        fieldType: type,
        fieldLabel: label
      })}
    >
      {({ instrument }) => (
        <>
          <LogOnChange value={value} eventType="Made a selection on a field" />
          <select
            id={id}
            name={id}
            required={required}
            className={required && 'inputObligatorio'}
            // onFocus={instrument('Field focused')}
            onChange={e => handleSelection(e)}
          >
            <option title="Seleccione una opción" value="">
              Seleccione una opción
            </option>
            {optionElements(options)}
          </select>
        </>
      )}
    </Amplitude>
  )
}

export default Selectfield
