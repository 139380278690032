import React, { useState, useEffect } from 'react'
import { Amplitude, LogOnChange } from '@amplitude/react-amplitude'

function Datefield(props) {
  const { id, required, type, label } = props
  const [value, setValue] = useState('')
  const [typing, setTyping] = useState(false)
  const [typed, setTyped] = useState('')

  useEffect(() => {
    const timeout = setTimeout(function() {
      setTyping(false)
      setTyped(value)
      console.log(`Stopped typing text: ${value}`)
    }, 1000)
    return () => clearTimeout(timeout)
  }, [value])

  function handleTyping(e) {
    setTyping(true)
    setValue(e.target.value)
  }

  return (
    <Amplitude
      eventProperties={inheritedProps => ({
        ...inheritedProps,
        scope: [...inheritedProps.scope, 'field'],
        fieldType: type,
        fieldLabel: label
      })}
    >
      {({ instrument }) => (
        <>
          <LogOnChange
            value={typing}
            eventType={typing ? 'Started typing on field' : 'Stopped typing on field'}
          />
          {/* <LogOnChange value={typed} eventType="Finished typing on field" /> */}
          <input
            type="date"
            id={id}
            name={id}
            required={required}
            className={required && 'inputObligatorio'}
            // onFocus={instrument('Field focused')}
            onChange={e => handleTyping(e)}
          />
        </>
      )}
    </Amplitude>
  )
}

export default Datefield
