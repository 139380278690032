import React from 'react'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

// amplitude-js no está preparada para SSR, lo extraemos a un Hook y cargamos el provider condicionalmente https://github.com/amplitude/Amplitude-JavaScript/issues/110
// import amplitude from 'amplitude-js'
import { AmplitudeProvider } from '@amplitude/react-amplitude'
import useAmplitude from './useAmplitude'

import Firebase, { FirebaseContext } from '../Firebase'

import { Landing, Home, Thankyou, Form } from '../Pages'

const amplitudeApiKey = process.env.REACT_APP_AMPLITUDE_KEY

function InstrumentedApp(props) {
  const amplitude = useAmplitude()

  return amplitude ? (
    <AmplitudeProvider amplitudeInstance={amplitude} apiKey={amplitudeApiKey}>
      {props.children}
    </AmplitudeProvider>
  ) : (
    props.children
  )
}

function App() {
  return (
    <>
      <InstrumentedApp>
        <FirebaseContext.Provider value={new Firebase()}>
          <Router>
            <Switch>
              <Route path="/landing">
                <Landing />
              </Route>
              <Route path="/form">
                <Form />
              </Route>
              <Route path="/gracias">
                <Thankyou />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </Router>
        </FirebaseContext.Provider>
      </InstrumentedApp>
    </>
  )
}

export default App
